import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, useServices } from 'cng-web-lib'
import NBCIUSCAPartyMasterApiUrls from '../../../apiUrls/NBCIUSCAPartyMasterApiUrls'
import pathMap from '../../../paths/pathMap'
import PartyMasterLookupsProvider from '../lookups/PartyMasterLookupsContext'
import PartyMasterViewContent from './PartyMasterViewContent'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton }
} = components

function ViewPage(props) {
  const { history, showNotification } = props
  const { id } = useParams()
  const [data, setData] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { deleteRecord, fetchRecord } = useServices()

  useEffect(() => {
    fetchRecord.execute(NBCIUSCAPartyMasterApiUrls.GET, id, (res) => {
      const data = res.content[0]

      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.NBCI_USCA_PARTY_MASTER_LIST_VIEW)
      } else {
        setData(data)
      }
    })
  }, [])

  if (!data) {
    return (
      <Grid container justify='center'>
        <Grid item xs='auto'>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  function handleDeleteParty() {
    deleteRecord.execute(NBCIUSCAPartyMasterApiUrls.DELETE, data, () => {
      showNotification('success', 'Delete succeeded')
      history.push(pathMap.NBCI_USCA_PARTY_MASTER_LIST_VIEW)
    })
  }

  return (
    <>
      <Card>
        <CardContent>
          <PartyMasterLookupsProvider>
            <PartyMasterViewContent
              data={data}
              history={history}
              showNotification={showNotification}
            />
          </PartyMasterLookupsProvider>
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.NBCI_USCA_PARTY_MASTER_LIST_VIEW)}
          >
            Back to party master list
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            <CngButton
              color='secondary'
              onClick={() => setConfirmDialog(true)}
              startIcon={<FontAwesomeIcon icon={['fal', 'trash']} size='sm' />}
            >
              Delete
            </CngButton>
            <CngButton
              startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
              onClick={() =>
                history.push(`${pathMap.NBCI_USCA_PARTY_MASTER}/edit/${id}`)
              }
            >
              Edit
            </CngButton>
          </Box>
        </CardActions>
      </Card>
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={handleDeleteParty}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default ViewPage
