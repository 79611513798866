import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import HSCodeApiUrls from 'src/apiUrls/HSCODEApiUrls'
import React from 'react'
import HSCodeKeys from 'src/constants/locale/key/HSCODE'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.H_S_CODE
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let hSCode = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: hSCode
      }
    )
    let intlcode = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.INTLCODE
    )
    let language = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.LANGUAGE
    )
    let indicator = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.INDICATOR
    )
    let subheadingId = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.SUBHEADING_ID
    )
    let descriptionEng = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.DESCRIPTION_ENG
    )
    let descriptionOth = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.DESCRIPTION_OTH
    )
    let uomId = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.UOM_ID
    )
    let hsCategoryId = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.HS_CATEGORY_ID
    )

    return {
      hSCode,
      tableTitle,
      intlcode,
      language,
      indicator,
      subheadingId,
      descriptionEng,
      descriptionOth,
      uomId,
      hsCategoryId
    }
  }

  const columns = [
    {
      field: "intlcode",
      title: translatedTextsObject.intlcode,
    },
    {
      field: "language",
      title: translatedTextsObject.language,
    },
    {
      field: "indicator",
      title: translatedTextsObject.indicator,
    },
    {
      field: "subheadingId",
      title: translatedTextsObject.subheadingId,
      type: "numeric",
    },
    {
      field: "descriptionEng",
      title: translatedTextsObject.descriptionEng,
    },
    {
      field: "descriptionOth",
      title: translatedTextsObject.descriptionOth,
    },
    {
      field: "uomId",
      title: translatedTextsObject.uomId,
    },
    {
      field: "hsCategoryId",
      title: translatedTextsObject.hsCategoryId,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: HSCodeApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: HSCodeApiUrls.EXPORT }}
              fetch={{ url: HSCodeApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              fetchCustomData={{ codeMType: "HSCode" }}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100, 500, 1000]
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
