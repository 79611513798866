import React, { useContext, useRef, useState } from 'react'
import { constants, useTranslation, useServices } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCIUSCAPartyMaster from '../../../constants/locale/key/NBCIUSCAPartyMaster'
import NBCIUSCAPartyMasterApiUrls from '../../../apiUrls/NBCIUSCAPartyMasterApiUrls'
import pathMap from '../../../paths/pathMap'
import Table from '../../../components/aciacehighway/Table'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import { PartyMasterLookupsContext } from '../lookups/PartyMasterLookupsContext'
import { Chip, Typography, useTheme } from '@material-ui/core'
import TinyChip from 'src/components/aciacehighway/TinyChip'

const {
  filter: { EQUAL, LIKE, IN }
} = constants

function TablePage(props) {
  const { history, showNotification } = props
  const tableRef = useRef(null)
  const [alertDialog, setAlertDialog] = useState({ open: false, parties: null })
  const { deleteRecord } = useServices()
  const { translate } = useTranslation(Namespace.NBCI_USCA_PARTY_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getLookupValue } = useContext(PartyMasterLookupsContext)

  function makeTranslatedTextsObject() {
    const inputPartyID = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.PARTY_I_D
    )
    const partyName = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.PARTY_NAME
    )
    const partyType = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.PARTY_TYPE
    )
    const usorcaMaster = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.USORCA_MASTER
    )

    return {
      inputPartyID,
      partyName,
      partyType,
      usorcaMaster
    }
  }

  const columns = [
    {
      field: 'inputPartyID',
      sortKey: 'inputPartyID',
      title: translatedTextsObject.inputPartyID
    },
    {
      field: 'partyName',
      sortKey: 'partyName',
      title: translatedTextsObject.partyName
    },
    {
      field: 'partyType',
      sortKey: 'partyType',
      title: translatedTextsObject.partyType,
      render: (rowData) => {
        return (
          <Typography component='div' variant='inherit'>
            {rowData.usorcaMaster=="CA" && rowData.partyType.split(',') // split party code by comma
              .map((type) => getLookupValue('partyTypeCAMaster', type)) // get each party code description
              .join(', ') // join back as single string
            }
            {rowData.usorcaMaster=="US" && rowData.partyType.split(',') // split party code by comma
              .map((type) => getLookupValue('partyTypeUSMaster', type)) // get each party code description
              .join(', ') // join back as single string
            }
            <TinyChip label={rowData.partyType} />
          </Typography>
        )
      }
    },
    {
      field: 'usorcaMaster',
      sortKey: 'usorcaMaster',
      title: translatedTextsObject.usorcaMaster
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.inputPartyID,
      type: 'textfield',
      name: 'inputPartyID',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.partyName,
      type: 'textfield',
      name: 'partyName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.partyType,
      type: 'checkbox',
      name: 'partyType',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Vendor',
          value: 'VN',
          filterValue: {
            value: 'VN'
          }
        },
        {
          fullWidth: true,
          label: 'Buyer',
          value: 'BY',
          filterValue: {
            value: 'BY'
          }
        },
        {
          fullWidth: true,
          label: 'Consignee',
          value: 'CN',
          filterValue: {
            value: 'CN'
          }
        },
        {
          fullWidth: true,
          label: 'Manufacturer/Producer',
          value: 'MF',
          filterValue: {
            value: 'MF'
          }
        },
        {
          fullWidth: true,
          label: 'Originator',
          value: 'OP',
          filterValue: {
            value: 'OP'
          }
        },
        {
          fullWidth: true,
          label: 'Declaration Party',
          value: 'DP',
          filterValue: {
            value: 'DP'
          }
        },
        {
          fullWidth: true,
          label: 'Exporter',
          value: 'EX',
          filterValue: {
            value: 'EX'
          }
        },
        {
          fullWidth: true,
          label: 'Broker',
          value: 'BP',
          filterValue: {
            value: 'BP|BR'
          }
        },
        {
          fullWidth: true,
          label: 'Shipper',
          value: 'SH',
          filterValue: {
            value: 'SH|SP'
          }
        },
        {
          fullWidth: true,
          label: 'Notify Party',
          value: 'NT',
          filterValue: {
            value: 'NT|NP'
          }
        },
        {
          fullWidth: true,
          label: 'Importer',
          value: 'IR',
          filterValue: {
            value: 'IR'
          }
        },
        {
          fullWidth: true,
          label: 'Certifier',
          value: 'CE',
          filterValue: {
            value: 'CE'
          }
        },
        {
          fullWidth: true,
          label: 'General',
          value: 'GE',
          filterValue: {
            value: 'GE'
          }
        },
        {
          fullWidth: true,
          label: 'Customer',
          value: 'LW',
          filterValue: {
            value: 'LW'
          }
        },
        {
          fullWidth: true,
          label: 'Bill To',
          value: 'BT',
          filterValue: {
            value: 'BT'
          }
        },
        {
          fullWidth: true,
          label: 'Submitter',
          value: '41',
          filterValue: {
            value: '41'
          }
        },
        {
          fullWidth: true,
          label: 'FDA Manufacturer',
          value: 'MF',
          filterValue: {
            value: 'MF'
          }
        },
        {
          fullWidth: true,
          label: 'Ship To',
          value: 'ST',
          filterValue: {
            value: 'ST'
          }
        },
        {
          fullWidth: true,
          label: 'Sold To',
          value: 'SO',
          filterValue: {
            value: 'SO'
          }
        },
        {
          fullWidth: true,
          label: 'Manufacturing Plant',
          value: 'MP',
          filterValue: {
            value: 'MP'
          }
        },
        {
          fullWidth: true,
          label: 'Consolidator',
          value: 'CS',
          filterValue: {
            value: 'CS'
          }
        },
        {
          fullWidth: true,
          label: 'Ultimate Consignee',
          value: 'UC',
          filterValue: {
            value: 'UC'
          }
        },
        {
          fullWidth: true,
          label: 'Grower',
          value: 'ZZ',
          filterValue: {
            value: 'ZZ'
          }
        },
        {
          fullWidth: true,
          label: 'Carrier',
          value: 'CA',
          filterValue: {
            value: 'CA'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.usorcaMaster,
      type: 'select',
      name: 'usorcaMaster',
      operatorType: EQUAL,
      options: [
        {
          fullWidth: true,
          label: 'US',
          value: 'US',
          filterValue: {
            value: 'US'
          }
        },
        {
          fullWidth: true,
          label: 'CA',
          value: 'CA',
          filterValue: {
            value: 'CA'
          }
        },
      ]
    },
  ]

  function handleDeleteParties() {
    if (!_.isEmpty(alertDialog.parties)) {
      deleteRecord.execute(
        NBCIUSCAPartyMasterApiUrls.DELETE,
        alertDialog.parties,
        () => {
          showNotification('success', 'Delete succeeded')
          setAlertDialog({ open: false, parties: null })

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => console.log(error)
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.NBCI_USCA_PARTY_MASTER_ADD_VIEW)
            },
            label: 'Create party'
          }
        ]}
        columns={columns}
        compact
        customRowActions={[
          {
            icon: ['fal', 'pen'],
            onClick: (rowData) =>
              history.push(`${pathMap.NBCI_USCA_PARTY_MASTER}/edit/${rowData.id}`),
            tooltip: () => 'Edit'
          }
        ]}
        exportData={{ url: NBCIUSCAPartyMasterApiUrls.EXPORT }}
        fetch={{ url: NBCIUSCAPartyMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => history.push(`${pathMap.NBCI_USCA_PARTY_MASTER}/view/${rowData.id}`)}
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
              setAlertDialog({ open: true, parties: rowData }),
          },
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={alertDialog.open}
        onClose={() => setAlertDialog({ open: false, parties: null })}
        onCancel={() => setAlertDialog({ open: false, parties: null })}
        onConfirm={handleDeleteParties}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
