import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import HSCodeKeys from 'src/constants/locale/key/HSCODE'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  intlcode: "",
  language: "",
  indicator: "",
  subheadingId: 0,
  descriptionEng: "",
  descriptionOth: "",
  uomId: "",
  hsCategoryId: "",
  codeMType: "HSCode" 
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.H_S_CODE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let hSCode = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.TITLE
    )
    let intlcode = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.INTLCODE
    )
    let language = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.LANGUAGE
    )
    let indicator = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.INDICATOR
    )
    let subheadingId = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.SUBHEADING_ID
    )
    let descriptionEng = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.DESCRIPTION_ENG
    )
    let descriptionOth = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.DESCRIPTION_OTH
    )
    let uomId = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.UOM_ID
    )
    let hsCategoryId = translate(
      Namespace.H_S_CODE,
      HSCodeKeys.HS_CATEGORY_ID
    )
    
    

    return {
      hSCode,
      intlcode,
      language,
      indicator,
      subheadingId,
      descriptionEng,
      descriptionOth,
      uomId,
      hsCategoryId
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.hSCode} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.intlcode}>
            <CngTextField
              name="intlcode"
              label={translatedTextsObject.intlcode + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.language}>
            <CngTextField
              name="language"
              label={translatedTextsObject.language + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.indicator}>
            <CngTextField
              name="indicator"
              label={translatedTextsObject.indicator + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.subheadingId}>
            <CngTextField
              name="subheadingId"
              type="number"
              label={translatedTextsObject.subheadingId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.descriptionEng}>
            <CngTextField
              name="descriptionEng"
              label={translatedTextsObject.descriptionEng}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.descriptionOth}>
            <CngTextField
              name="descriptionOth"
              label={translatedTextsObject.descriptionOth}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.uomId}>
            <CngTextField
              name="uomId"
              label={translatedTextsObject.uomId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.hsCategoryId}>
            <CngTextField
              name="hsCategoryId"
              label={translatedTextsObject.hsCategoryId}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
