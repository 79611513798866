import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCIUSCAPartyMaster from '../../../constants/locale/key/NBCIUSCAPartyMaster'
import { PartyMasterLookupsContext } from '../lookups/PartyMasterLookupsContext'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import _ from 'lodash'
import { Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PartyMasterViewContent(props) {
  const { data } = props

  const { getLookupValue, getCountryStateLabel } = useContext(PartyMasterLookupsContext)
  const theme = useTheme()
  const { translate } = useTranslation(Namespace.NBCI_USCA_PARTY_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const partyMaster = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.TITLE
    )
    const inputPartyID = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.PARTY_I_D
    )
    const partyName = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.PARTY_NAME
    )
    const nameDBA = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.D_B_A
    )
    const partyType = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.PARTY_TYPE
    )
    const address1 = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.ADDRESS1
    )
    const address2 = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.ADDRESS2
    )
    const address3 = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.ADDRESS3
    )
    const cityName = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.CITY
    )
    const stateCode = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.STATE
    )
    const countryCode = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.COUNTRY
    )
    const postalCode = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.POSTAL_CODE
    )
    const contactPerson = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.CONTACT_PERSON
    )
    const telNo = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.TEL_NUMBER
    )
    const faxNo = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.FAX
    )
    const email = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.EMAIL
    )
    const scac = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.SCAC_CODE
    )
    const ctpatIndicator = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.C_PAT
    )
    const partyIdentifierSection = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.PartyIdentifierSection.TITLE
    )
    const noteLabel = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.NOTE_LABEL
    )
    const note = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.NOTE
    )
    const note2 = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.NOTE2
    )
    const partyIdentifierCode = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.PartyIdentifierSection.PARTY_IDENTIFIER_CODE
    )
    const partyIdentifierNumber = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.PartyIdentifierSection.PARTY_IDENTIFIER_NUMBER
    )

    return {
      partyMaster,
      inputPartyID,
      partyType,
      nameDBA,
      partyName,
      scac,
      ctpatIndicator,
      address1,
      address2,
      address3,
      cityName,
      countryCode,
      stateCode,
      postalCode,
      contactPerson,
      telNo,
      faxNo,
      email,
      partyIdentifierSection,
      noteLabel,
      note,
      noteLabel,
      note2,
      partyIdentifierCode,
      partyIdentifierNumber,
    }
  }

  const { usorcaMaster } = data

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection title='Product information'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color='textSecondary'>
                {getLookupValue('usorcaMaster', usorcaMaster)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.inputPartyID}>
                {data.inputPartyID}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.partyType}>
                {data.partyType}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={translatedTextsObject.nameDBA}>
                {data.nameDBA}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={translatedTextsObject.partyName}>
                {data.partyName}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {usorcaMaster === 'US' && (
                <CngField label={translatedTextsObject.scac}>
                  {data.scac}
                </CngField>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.ctpatIndicator}>
                <Grid container spacing={2}>
                  <Grid item xs='auto'>
                    <FontAwesomeIcon
                      color={
                        data.ctpatIndicator === 'Y'
                          ? theme.palette.primary.main
                          : theme.palette.error.main
                      }
                      icon={[
                        'fal',
                        data.ctpatIndicator === 'Y'
                          ? 'check'
                          : 'ban'
                      ]}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant='body2'>
                      {translatedTextsObject.ctpatIndicator}
                    </Typography>
                  </Grid>
                </Grid>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.address1}>
                {data.address1}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.address2}>
                {data.address2}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.address3}>
                {data.address3}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.cityName}>
                {data.cityName}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.countryCode}>
                {usorcaMaster === 'US' && getLookupValue('country', data.countryCode)}
                {usorcaMaster === 'CA' && getLookupValue('naCountry', data.countryCode)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.stateCode}>
                {getCountryStateLabel(data.countryCode, data.stateCode)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.postalCode}>
                {data.postalCode}
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs='auto'>
            <Typography variant='body2' style={{ fontWeight: 600 }}>
              Local Contact Information
            </Typography>
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.contactPerson}>
              {data.contactPerson}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.telNo}>
              {data.telNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.faxNo}>
              {data.faxNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.email}>
              {data.email}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.partyIdentifierSection}>
          {!_.isEmpty(data.partyIdentifierSection) ? (
            <Grid container spacing={2}>
              {data.partyIdentifierSection.map((partyIdentifier, index) => (
                <React.Fragment key={partyIdentifier.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <CngField label={translatedTextsObject.partyIdentifierCode}>
                          {getLookupValue('partyIdentifierCode', partyIdentifier.partyIdentifierCode)}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CngField label={translatedTextsObject.partyIdentifierNumber}>
                          {partyIdentifier.partyIdentifierNumber}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {index + 1 < data.partyIdentifierSection.length && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default PartyMasterViewContent
