import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import NaCodeMaintenanceApiUrls from '../../../apiUrls/NaCodeMaintenanceApiUrls'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const USInvoiceLookupsContext = React.createContext()

const USInvoiceLookupsConsumer = USInvoiceLookupsContext.Consumer

const USInvoiceLookupsProvider = ({ children }) => {
  const [lookups, setLookups] = useState(null)
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_INV_CURR' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_INV_TRANSPORT_MODE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.COUNTRY),
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'PARTY_TYPE_US_MASTER' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'PARTY_MASTER_IDENTIFIER_CODE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_INV_PARTY_BILLED' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'NBCI_US_INV_REFERENCE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_INV_STATUS' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'NBCI_PROD_NET_COST' }
      ]),
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'set_indicator', operator: EQUAL, value: 'IFX_US' }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) =>
          res.content.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.code]: curr.descriptionEn
            }),
            {}
          )
      ),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_INV_WEIGHT_UOM' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_INV_DETAIL_REF_TYPE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_INV_DETAIL_SERVICE_ADD_DED' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_INV_SUMMARY_PACKAGE_TYPE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_INV_SUMMARY_UOM' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_INV_SUMMARY_FREIGHT' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'NBCI_RESPONSE_STATUS' }
      ]),
    ]).then(
      ([
        currency,
        transportationMode,
        country,
        countryStates,
        partyType,
        partyIdentifierCode,
        partyBilled,
        reference,
        status,
        netCost,
        usaphiportOfEntry,
        weightUom,
        referenceType,
        additionDeductionCode,
        packageType,
        dimensionsUOM,
        freightClass,
        responseStatus,
      ]) => {
        const states = Object.keys(country).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        const localLookups = {
          instructionsToBroker: {
            Y: 'Arrange Customs Clearance',
            N: 'Inbond to Destination'
          },
          billChargesTo: {
            E: 'Exporter',
            S: 'Sold To',
            C: 'Consignee'
          },
          freightCharges: {
            P: 'Prepaid',
            C: 'Collect'
          },
          usaphisTitle: {
            AUTHORIZEDAGENT: 'Authorozed Agent',
            OWNER: 'Owner',
            IMPORTER: 'Agent'
          },
          cerifierType: {
            IR: 'IMPORTER',
            EX: 'EXPORTER',
            MP: 'PRODUCER'
          },
          transportTermsCode: {
            CFR: 'Cost and Freight',
            CIF: 'Cost, Insurance, and Freight',
            CIP: 'Carriage and Insurance Paid To',
            CPT: 'Carriage Paid To',
            DAF: 'Delivered at Frontier',
            DDP: 'Delivered Duty Paid',
            DDU: 'Deliver Duty Unpaid',
            DEQ: 'Delivered Ex Quay',
            DES: 'Delivered Ex Ship',
            EXW: 'Ex Works',
            FAS: 'Free Alongside Ship',
            FCA: 'Free Carrier',
            FOB: 'Free on Boar',
          },
        }

        setLookups({
          ...localLookups,
          currency,
          transportationMode,
          country,
          states,
          partyType,
          partyIdentifierCode,
          partyBilled,
          reference,
          status,
          netCost,
          usaphiportOfEntry,
          weightUom,
          referenceType,
          additionDeductionCode,
          packageType,
          dimensionsUOM,
          freightClass,
          responseStatus,
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    if (Object.keys(lookups.country).includes(country)) {
      const result = lookups.states[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  return (
    <USInvoiceLookupsContext.Provider
      value={{ lookups, getLookupValue, getCountryStateLabel }}
    >
      {children}
    </USInvoiceLookupsContext.Provider>
  )
}

export default USInvoiceLookupsProvider

export { USInvoiceLookupsContext, USInvoiceLookupsConsumer }
