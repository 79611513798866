import React, { useEffect, useState } from 'react'
import { components, constants, DataFlattener, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCIPartyMasterKeys from '../../../constants/locale/key/NBCIUSCAPartyMaster'
import { Grid, Typography, Divider } from '@material-ui/core'
import { NaStateCodeAutoCompleteField } from '../../../components/na'
import makeValidationSchema from './MakeValidationSchema'
import CngSection from '../../../components/cngcomponents/CngSection'
import RadioButtonGroup from '../../../components/aciacehighway/RadioButtonGroup'
import Switch from '../../../components/Switch'
import Alert from '../../../components/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormContext , useWatch} from 'react-hook-form'
import PartyIdentifierSection from './PartyIdentifierSection'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { NaNbciCountryCodeAutocompleteField } from 'src/components/na/autocomplete/codemaintenance'

const {
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField
    },
  },
  table: { useFetchCodeMaintenanceLookup },
  CngGridItem,
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  usorcaMaster: "US",
  acctID: "",
  inputPartyID: "",
  partyName: "",
  nameDBA: "",
  partyType: "",
  address1: "",
  address2: "",
  address3: "",
  cityName: "",
  stateCode: "",
  countryCode: "",
  postalCode: "",
  contactPerson: "",
  telNo: "",
  faxNo: "",
  email: "",
  scac: "",
  ctpatIndicator: "N",
  partyIdentifierSection: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const [productMasterTypes, setProductMasterTypes] = useState([])
  const { setValue, watch } = useFormContext()
  const usorcaMaster = watch('usorcaMaster')
  const ctpatIndicator = watch('ctpatIndicator')

  const { translate } = useTranslation(Namespace.NBCI_USCA_PARTY_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    async function fetchLookups() {
      try {
        const productMasterTypes = await fetchCodeMaintenanceLookup(
          CodeMaintenanceType.CODE_MASTER,
          undefined,
          [{ field: 'codeType', operator: EQUAL, value: 'US_OR_CA_MASTER' }]
        )
        
        const result = []

        for (const [key, value] of Object.entries(productMasterTypes)) {
          result.push({ label: value, value: key })
        }
  
        setProductMasterTypes(result)
        setValue('usorcaMaster', usorcaMaster)
      } catch (error) {
        console.error(error)
      }
    }

    fetchLookups()
  }, [])

  
  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    const partyMaster = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.TITLE
    )
    const inputPartyID = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.PARTY_I_D
    )
    const partyName = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.PARTY_NAME
    )
    const nameDBA = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.D_B_A
    )
    const partyType = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.PARTY_TYPE
    )
    const address1 = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.ADDRESS1
    )
    const address2 = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.ADDRESS2
    )
    const address3 = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.ADDRESS3
    )
    const cityName = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.CITY
    )
    const stateCode = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.STATE
    )
    const countryCode = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.COUNTRY
    )
    const postalCode = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.POSTAL_CODE
    )
    const contactPerson = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.CONTACT_PERSON
    )
    const telNo = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.TEL_NUMBER
    )
    const faxNo = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.FAX
    )
    const email = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.EMAIL
    )
    const scac = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.SCAC_CODE
    )
    const ctpatIndicator = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.C_PAT
    )
    const partyIdentifierSection = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.PartyIdentifierSection.TITLE
    )
    const noteLabel = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.NOTE_LABEL
    )
    const note = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.NOTE
    )
    const note2 = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIPartyMasterKeys.NOTE2
    )

    return {
      partyMaster,
      inputPartyID,
      partyType,
      nameDBA,
      partyName,
      scac,
      ctpatIndicator,
      address1,
      address2,
      address3,
      cityName,
      countryCode,
      stateCode,
      postalCode,
      contactPerson,
      telNo,
      faxNo,
      email,
      partyIdentifierSection,
      noteLabel,
      note,
      noteLabel,
      note2
    }
  }

	return (
    <CngSection title={translatedTextsObject.partyMaster}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RadioButtonGroup
            onChange={(event) => setValue('usorcaMaster', event.target.value)}
            options={productMasterTypes}
            value={usorcaMaster}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.inputPartyID}>
              <CngTextField
                name='inputPartyID'
                label={translatedTextsObject.inputPartyID}
                isRequired
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.partyType}>
              <CngCodeMasterAutocompleteField
                name='partyType'
                codeType={usorcaMaster === 'US' ? 'PARTY_TYPE_US_MASTER' : 'PARTY_TYPE_CA_MASTER' }
                key={usorcaMaster}
                label={translatedTextsObject.partyType}
                isRequired
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.nameDBA}>
              <CngTextField
                name='nameDBA'
                label={translatedTextsObject.nameDBA}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyName}>
              <CngTextField
                name='partyName'
                label={translatedTextsObject.partyName}
                isRequired
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            {usorcaMaster === 'US' && (
              <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.scac}>
                <CngTextField
                  name='scac'
                  label={translatedTextsObject.scac}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            )}
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.ctpatIndicator}>
              <Switch
                name='ctpatIndicator'
                label={translatedTextsObject.ctpatIndicator}
                onChange={(e) => setValue('ctpatIndicator', e.target.checked ? 'Y' : 'N')}
                checked={ctpatIndicator === 'Y'}
                disabled={disabled}
                fullWidth
              />
            </CngGridItem>
            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.address1}>
              <CngTextField
                name='address1'
                label={translatedTextsObject.address1}
                isRequired
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.address2}>
              <CngTextField
                name='address2'
                label={translatedTextsObject.address2}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.address3}>
              <CngTextField
                name='address3'
                label={translatedTextsObject.address3}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.cityName}>
              <CngTextField
                name='cityName'
                label={translatedTextsObject.cityName}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            {usorcaMaster === 'US' && (
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.countryCode}>
              <CngCountryAutocompleteField
                name='countryCode'
                label={translatedTextsObject.countryCode}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            )}
             {usorcaMaster === 'CA' && (
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.countryCode}>
              <NaNbciCountryCodeAutocompleteField
                name='countryCode'
                label={translatedTextsObject.countryCode}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            )}
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.stateCode}>
              <NaStateCodeAutoCompleteField
                name='stateCode'
                label={translatedTextsObject.stateCode}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.postalCode}>
              <CngTextField
                name='postalCode'
                label={translatedTextsObject.postalCode}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid alignItems='center' container spacing={2}>
            <Grid item xs='auto'>
              <Typography variant='body2' style={{ fontWeight: 600 }}>
                Local Contact Information
              </Typography>
            </Grid>
            <Grid item xs>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} shouldHide={shouldHideMap.contactPerson}>
              <CngTextField
                name='contactPerson'
                label={translatedTextsObject.contactPerson}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.telNo}>
              <CngTextField
                name='telNo'
                label={translatedTextsObject.telNo}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.faxNo}>
              <CngTextField
                name='faxNo'
                label={translatedTextsObject.faxNo}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.email}>
              <CngTextField
                name='email'
                label={translatedTextsObject.email}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PartyIdentifierSection title={translatedTextsObject.partyIdentifierSection} />
        </Grid>
        <Grid item xs={12}>
          <Alert
            severity='info'
            icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
          >
            <strong>{translatedTextsObject.noteLabel}:</strong>
            &nbsp;{translatedTextsObject.note}
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Alert
            severity='info'
            icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
          >
            <strong>{translatedTextsObject.noteLabel}:</strong>
            &nbsp;{translatedTextsObject.note2}
          </Alert>
        </Grid>
      </Grid>
    </CngSection>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
