import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCIUSCAPartyMaster from '../../../constants/locale/key/NBCIUSCAPartyMaster'
import makeValidationSchema from './PartyIdentifierSectionMakeValidationSchema'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyIdentifierCode: '',
  partyIdentifierNumber: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_USCA_PARTY_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const partyIdentifierCode = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.PartyIdentifierSection.PARTY_IDENTIFIER_CODE
    )
    const partyIdentifierNumber = translate(
      Namespace.NBCI_USCA_PARTY_MASTER,
      NBCIUSCAPartyMaster.PartyIdentifierSection.PARTY_IDENTIFIER_NUMBER
    )

    return { partyIdentifierCode, partyIdentifierNumber }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.partyIdentifierCode}>
        <CngCodeMasterAutocompleteField
          name='partyIdentifierCode'
          label={translatedTextsObject.partyIdentifierCode}
          disabled={disabled}
          size='small'
          codeType='PARTY_MASTER_IDENTIFIER_CODE'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.partyIdentifierNumber}>
        <CngTextField
          name='partyIdentifierNumber'
          label={translatedTextsObject.partyIdentifierNumber}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const PartyIdentifierSectionFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PartyIdentifierSectionFormProperties
