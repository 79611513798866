import React, { useEffect, useState } from 'react'
import { components, constants } from 'cng-web-lib'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const ProductMasterLookupsContext = React.createContext()

const ProductMasterLookupsConsumer = ProductMasterLookupsContext.Consumer

const ProductMasterLookupsProvider = ({ children }) => {
  const [lookups, setLookups] = useState(null)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_OR_CA_MASTER' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'ORIGIN_CRITERION' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'NBCI_PROD_NET_COST' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.COUNTRY),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.UOM),
    ]).then(
      ([
        usorcaMaster,
        naftaCriteria,
        netCost,
        country,
        uom,
      ]) => {
        setLookups({
          usorcaMaster,
          naftaCriteria,
          netCost,
          country,
          uom,
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  return (
    <ProductMasterLookupsContext.Provider
      value={{ lookups, getLookupValue }}
    >
      {children}
    </ProductMasterLookupsContext.Provider>
  )
}

export default ProductMasterLookupsProvider

export { ProductMasterLookupsContext, ProductMasterLookupsConsumer }
