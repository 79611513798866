import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import NaCodeMaintenanceApiUrls from '../../../apiUrls/NaCodeMaintenanceApiUrls'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const PartyMasterLookupsContext = React.createContext()

const PartyMasterLookupsConsumer = PartyMasterLookupsContext.Consumer

const PartyMasterLookupsProvider = ({ children }) => {
  const [lookups, setLookups] = useState(null)
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'US_OR_CA_MASTER' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'PARTY_MASTER_IDENTIFIER_CODE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.COUNTRY),
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'indicator', operator: EQUAL, value: 'cci' }
          ],
          customData: { codeMType: 'NACountryCode' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'PARTY_TYPE_CA_MASTER' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'PARTY_TYPE_US_MASTER' }
      ]),
    ]).then(([usorcaMaster, partyIdentifierCode, country, countryStates,naCountry, partyTypeCAMaster, partyTypeUSMaster]) => {
      const states = Object.keys(country).reduce((acc, countryCode) => {
        acc[countryCode] = countryStates.filter(
          (state) => state.code === countryCode
        )

        return acc
      }, {})
      setLookups({ usorcaMaster, partyIdentifierCode, country, states, naCountry,partyTypeCAMaster,partyTypeUSMaster })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    if (Object.keys(lookups.country).includes(country)) {
      const result = lookups.states[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  return (
    <PartyMasterLookupsContext.Provider value={{ lookups, getLookupValue, getCountryStateLabel }}>
      {children}
    </PartyMasterLookupsContext.Provider>
  )
}

export default PartyMasterLookupsProvider

export { PartyMasterLookupsContext, PartyMasterLookupsConsumer }
