import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  return Yup.object({
    partyIdentifierCode: Yup.string(),
    partyIdentifierNumber: Yup.string()
  })
}

export default makeValidationSchema
